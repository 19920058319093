.inflex {
  display: inline-flex;
  align-items: center;
}

.m0 {
  margin: 0 !important;
}

.nowrap {
  white-space: nowrap !important;
}

.show-phover {
  /* "Show on parent hover" */
  visibility: hidden;
}

@media (hover: hover) {
  .phover:hover .show-phover {
    /* "Parent hover" */
    visibility: visible;
  }
}
